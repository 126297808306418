import { Component, Input } from '@angular/core';
import { ThemeData } from 'src/app/interfaces';

export interface PassportNavigationComponentData {
  theme: ThemeData;
}

@Component({
  selector: 'app-passport-navigation',
  templateUrl: './passport-navigation.component.html',
  styleUrls: ['./passport-navigation.component.scss'],
})
export class PassportNavigationComponent {
  @Input() inputData: PassportNavigationComponentData;
  data: PassportNavigationComponentData;
  theme = {
    primary: { background: '#006239', text: '#FFFFFF' },
    accent: { background: '#913a00', text: '#FFFFFF' },
    image: '',
  };
  async ngOnInit() {
    if (this.inputData) {
      this.data = this.inputData;
      if (this.data.theme) {
        this.theme = this.data.theme;
      }
    }
  }
}
