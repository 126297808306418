import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TownshipPublicSettings, VoucherGroup } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { CustomValidators } from '../validators/custom-validators';
import {
  getHeaderImageUrl,
  fixTermsUrl,
  getEnvName,
  getTownshipColors,
} from '../globals';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
const app = initializeApp(environment.firebase);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');

@Component({
  selector: 'app-buy-vouchers',
  templateUrl: './buy-vouchers.component.html',
  styleUrls: ['./buy-vouchers.component.scss'],
})
export class BuyVouchersComponent implements OnInit {
  // @ViewChild('stepper') private stepper: MatStepper;
  env = environment;
  iframeError = false;
  awaitingResponse: boolean;
  townshipId: string;
  voucherGroupId: string;
  township: TownshipPublicSettings;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupData: VoucherGroup;
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  formStep1: UntypedFormGroup;
  formStep2: UntypedFormGroup;
  formStep3: UntypedFormGroup;
  formStep4: UntypedFormGroup;
  availableVouchers: number;
  termsRows: number;
  headerImg: string;
  showHeaderImg: boolean;
  townshipUrlFixed: string;
  step: number = 1;
  extraStepExists = false;
  sisowBanks: any[] = [];
  paymentSystem: string;

  envName: any = {};
  theme: any;

  issuers: { id: string; name: string; imgPath: string }[] = [];
  showStep: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.formStep1 = this.fb.group({
      amount: [, [Validators.required, Validators.min(1)]],
    });
    this.formStep2 = this.fb.group({
      type: ['customer'],
      delivery: [''],
      name: ['', Validators.required],
      email: [, [Validators.required, Validators.email]],
      emailConfirm: [, [Validators.required, Validators.email]],
      postal: ['', [Validators.required, CustomValidators.postalValidator]],
      city: ['', Validators.required],
      street: ['', Validators.required],
      houseNumberAddition: [''],
      houseNumber: [
        '',
        [Validators.required, CustomValidators.numberInput(false, false, 0)],
      ],
      deliveryStreet: ['', Validators.required],
      deliveryHouseNumber: [
        '',
        [Validators.required, CustomValidators.numberInput(false, false, 0)],
      ],
      deliveryHouseNumberAddition: [''],
      deliveryPostal: [
        '',
        [Validators.required, CustomValidators.postalValidator],
      ],
      deliveryCity: ['', Validators.required],
    });
    this.formStep3 = this.fb.group({
      personalMessage: [],
    });
    this.formStep4 = this.fb.group({
      terms: [false, Validators.requiredTrue],
      bank: [, Validators.required],
    });
    this.route.params.subscribe((params) => {
      this.townshipId = params.townshipId;
      this.voucherGroupId = params.voucherGroupId;
      if (this.townshipId && this.voucherGroupId) {
        this.getInfo();
      } else {
        this.iframeError = true;
      }
    });

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    this.formStep2.controls.type.valueChanges.subscribe((value) => {
      if (value === 'company') {
        this.formStep2.addControl(
          'companyName',
          new UntypedFormControl('', Validators.required)
        );
      } else if (this.formStep2.controls.companyName) {
        this.formStep2.removeControl('companyName');
      }
    });
    this.formStep2.controls.houseNumber.valueChanges
      .pipe(debounceTime(600))
      .subscribe(async (value) => {
        await this.getAddress();
      });
    this.formStep2.controls.postal.valueChanges
      .pipe(debounceTime(600))
      .subscribe(async (value) => {
        await this.getAddress();
      });
    this.formStep2.controls.deliveryHouseNumber.valueChanges
      .pipe(debounceTime(600))
      .subscribe(async (value) => {
        await this.getDeliveryAddress();
      });
    this.formStep2.controls.deliveryPostal.valueChanges
      .pipe(debounceTime(600))
      .subscribe(async (value) => {
        await this.getDeliveryAddress();
      });
    this.formStep2.controls.delivery.valueChanges.subscribe((value) => {
      const rawForm = this.formStep2.getRawValue();
      if (value === 'differentfrom') {
        this.formStep2.controls.deliveryStreet.enable();
        this.formStep2.controls.deliveryStreet.patchValue('');

        this.formStep2.controls.deliveryHouseNumber.enable();
        this.formStep2.controls.deliveryHouseNumber.patchValue('');

        this.formStep2.controls.deliveryHouseNumberAddition.enable();
        this.formStep2.controls.deliveryHouseNumberAddition.patchValue('');

        this.formStep2.controls.deliveryPostal.enable();
        this.formStep2.controls.deliveryPostal.patchValue('');

        this.formStep2.controls.deliveryCity.enable();
        this.formStep2.controls.deliveryCity.patchValue('');
      }
      if (value == 'equalto') {
        if (this.formStep2.controls.deliveryStreet.enabled) {
          this.formStep2.controls.deliveryStreet.disable();
        }
        if (this.formStep2.controls.deliveryHouseNumber.enabled) {
          this.formStep2.controls.deliveryHouseNumber.disable();
        }
        if (this.formStep2.controls.deliveryHouseNumberAddition.enabled) {
          this.formStep2.controls.deliveryHouseNumberAddition.disable();
        }
        if (this.formStep2.controls.deliveryPostal.enabled) {
          this.formStep2.controls.deliveryPostal.disable();
        }
        if (this.formStep2.controls.deliveryCity.enabled) {
          this.formStep2.controls.deliveryCity.disable();
        }
        if (
          this.formStep2.value.street !== rawForm.deliveryStreet ||
          this.formStep2.controls.deliveryStreet.enabled
        ) {
          this.formStep2.controls.deliveryStreet.patchValue(
            this.formStep2.getRawValue().street
          );
        }
        if (this.formStep2.value.houseNumber !== rawForm.deliveryHouseNumber) {
          this.formStep2.controls.deliveryHouseNumber.patchValue(
            this.formStep2.value.houseNumber,
            { emitEvent: false }
          );
        }
        if (
          this.formStep2.value.houseNumberAddition !==
          rawForm.deliveryHouseNumberAddition
        ) {
          this.formStep2.controls.deliveryHouseNumberAddition.patchValue(
            this.formStep2.value.houseNumberAddition
          );
        }
        if (this.formStep2.value.postal !== rawForm.deliveryPostal) {
          this.formStep2.controls.deliveryPostal.patchValue(
            this.formStep2.value.postal,
            { emitEvent: false }
          );
        }
        if (this.formStep2.value.city !== rawForm.deliveryCity) {
          this.formStep2.controls.deliveryCity.patchValue(
            this.formStep2.getRawValue().city
          );
        }
      }
    });

    this.formStep2.controls.email.valueChanges.subscribe((value) => {
      this.checkEmails(value, this.formStep2.value.emailConfirm);
    });
    this.formStep2.controls.emailConfirm.valueChanges.subscribe((value) => {
      this.checkEmails(this.formStep2.value.email, value);
    });
  }

  checkEmails(email, emailConfirm) {
    if (
      this.formStep2.controls.email.dirty &&
      this.formStep2.controls.emailConfirm.dirty &&
      email !== emailConfirm
    ) {
      this.formStep2.controls.emailConfirm.setErrors({
        emailsNeedToBeTheSame: true,
      });
    } else if (
      this.formStep2.controls.emailConfirm.hasError('emailsNeedToBeTheSame')
    ) {
      this.formStep2.controls.emailConfirm.setErrors(null);
      this.formStep2.controls.emailConfirm.updateValueAndValidity();
    }
  }

  async getAddress() {
    this.formStep2.controls.city.disable({ emitEvent: false });
    this.formStep2.controls.street.disable({ emitEvent: false });
    this.formStep2.controls.postal.setErrors(
      { apiError: null },
      { emitEvent: false }
    );
    this.formStep2.controls.postal.updateValueAndValidity({ emitEvent: false });
    if (
      this.formStep2.value.postal !== '' &&
      this.formStep2.controls.postal.valid &&
      this.formStep2.value.houseNumber !== ''
    ) {
      const postal: string = this.formStep2.value.postal.replace(/\s+/g, '');
      const houseNumber: string = this.formStep2.value.houseNumber.trim();
      const callable = httpsCallable(functions, 'httpGetAddressWithApi', {
        timeout: 60000,
      });
      const result = await callable({
        postal: postal,
        houseNumber: houseNumber,
      });
      if (result.data['street'] && result.data['city']) {
        this.formStep2.controls.street.patchValue(result.data['street']);
        this.formStep2.controls.city.patchValue(result.data['city']);
      } else if (result.data['name'] === 'Error') {
        this.formStep2.controls.city.enable();
        this.formStep2.controls.street.enable();
        this.formStep2.controls.postal.setErrors({ apiError: true });
      }
    }
  }

  async getDeliveryAddress() {
    this.formStep2.controls.deliveryCity.disable({ emitEvent: false });
    this.formStep2.controls.deliveryStreet.disable({ emitEvent: false });
    this.formStep2.controls.deliveryPostal.setErrors(
      { apiError: null },
      { emitEvent: false }
    );
    this.formStep2.controls.deliveryPostal.updateValueAndValidity({
      emitEvent: false,
    });
    if (
      this.formStep2.value.deliveryPostal !== '' &&
      this.formStep2.controls.deliveryPostal.valid &&
      this.formStep2.value.deliveryHouseNumber !== ''
    ) {
      const postal: string = this.formStep2.value.deliveryPostal.replace(
        /\s+/g,
        ''
      );
      const houseNumber: string =
        this.formStep2.value.deliveryHouseNumber.trim();
      const callable = httpsCallable(functions, 'httpGetAddressWithApi', {
        timeout: 60000,
      });
      const result = await callable({
        postal: postal,
        houseNumber: houseNumber,
      });
      if (result.data['street'] && result.data['city']) {
        this.formStep2.controls.deliveryStreet.patchValue(
          result.data['street']
        );
        this.formStep2.controls.deliveryCity.patchValue(result.data['city']);
      } else if (result.data['name'] === 'Error') {
        this.formStep2.controls.deliveryCity.enable();
        this.formStep2.controls.deliveryStreet.enable();
        this.formStep2.controls.deliveryPostal.setErrors({ apiError: true });
      }
    }
  }

  getError(name, formstep = 'formStep2') {
    const field = this[formstep].get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('number') || field.hasError('numberInput')) {
        error = 'number';
      }
      if (field.hasError('pattern') || field.hasError('postalValidator')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      if (field.hasError('emailsNeedToBeTheSame')) {
        error = 'emails_must_match';
      }
      if (error) {
        const res = this.translate.instant(`errors.${error}`) as string;
        return res;
      }
    }
    return '';
  }
  async getInfo() {
    this.township = (
      await getDoc(
        doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
      )
    ).data() as TownshipPublicSettings;

    this.theme = await getTownshipColors(this.township);
    this.townshipUrlFixed = fixTermsUrl(this.township.termsUrl);
    this.envName = getEnvName(this.township.voucherPrefix);

    this.voucherGroupDoc = this.db.doc<VoucherGroup>(
      'township/' + this.townshipId + '/voucherGroups/' + this.voucherGroupId
    );
    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as VoucherGroup;
        this.termsRows = 5;
        console.log('data', data);
        data.id = actions.payload['id'];

        if (!data.hideHeaderImage) {
          this.showHeaderImg = true;
        } else {
          this.showHeaderImg = false;
        }

        if (data.voucherPrice === 0) {
          this.formStep1.addControl(
            'value',
            new UntypedFormControl('', [
              Validators.required,
              Validators.min(10),
              Validators.max(300),
            ])
          );
        }

        if (data.requestPhone) {
          this.formStep2.addControl(
            'phone',
            new UntypedFormControl('', Validators.required)
          );
        }
        if (data.type === 'claim') {
          this.formStep2.addControl(
            'number',
            new UntypedFormControl('', Validators.required)
          );
        }

        console.log('data', data);
        data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
        data.activatedVouchers = data.activatedVouchers
          ? data.activatedVouchers
          : 0;
        data.reservedVouchers = data.reservedVouchers
          ? data.reservedVouchers
          : 0;
        if (data.maxVouchers && data.totalVouchers > data.maxVouchers) {
          data.totalVouchers = data.maxVouchers;
        }
        if (!data.claimedVouchers) {
          data.claimedVouchers = 0;
        }
        if (!data.activatedVouchers) {
          data.activatedVouchers = 0;
        }
        if (!data.reservedVouchers || data.reservedVouchers < 0) {
          data.reservedVouchers = 0;
        }
        if (!data.toBeActivated) {
          data.toBeActivated = 0;
        }

        if (data.sellType == 'physical') {
          if (data.personalisedNote) {
            this.extraStepExists = true;
          }
        }

        const reservedVouchers = data.reservedVouchers + data.toBeActivated;
        this.availableVouchers =
          data.totalVouchers - data.activatedVouchers - reservedVouchers;

        console.log('this.availableVouchers', this.availableVouchers);
        if (this.availableVouchers <= 0 && data.type !== 'requestActivated') {
          this.formStep1.disable();
          this.formStep2.disable();
          this.formStep3.disable();
          this.formStep4.disable();
        } else {
          this.formStep1.enable();
          this.formStep2.enable();
          this.formStep3.enable();
          this.formStep4.enable();
        }
        return { ...data };
      })
    );
    this.voucherGroup.subscribe(async (voucherGroup) => {
      console.log('voucherGroup', voucherGroup);
      this.voucherGroupData = voucherGroup;
      this.paymentSystem = voucherGroup.paymentProvider;
      if (this.paymentSystem === 'ideal') {
        // get issuers
        this.showStep = true;
        const issuersRef = doc(
          this.db.firestore,
          `township/${this.townshipId}/issuers/ideal-ing`
        );
        const issuers = (await getDoc(issuersRef)).data() as {
          date: string;
          issuers: { issuerID: any; issuerName: any }[];
        };

        this.issuers = [];
        for (const issuer of issuers.issuers) {
          console.log('issuer', issuer);
          this.issuers.push({
            id: issuer.issuerID[0],
            name: issuer.issuerName[0],
            imgPath: this.getBankImg(issuer.issuerName[0]),
          });
        }
        this.issuers = this.issuers.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }
    });
  }
  getCurrencyString(number: number) {
    let newNumber;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }
  ceil(number) {
    return Math.ceil(number);
  }

  stepForward() {
    this.step = this.step + 1;
  }

  stepBackward() {
    this.step = this.step - 1;
  }

  async submit(issuerId?: number) {
    if (!this.formStep4.valid) {
      this.snackBar.open('Je moet de voorwaarden eerst accepteren', 'X', {
        duration: 2500,
      });
      return;
    }
    if (!this.awaitingResponse && this.availableVouchers > 0) {
      this.awaitingResponse = true;
      const email = this.formStep2.value.email;
      const emailConfirm = this.formStep2.value.emailConfirm;
      const amount = this.formStep1.value.amount;
      if (email !== emailConfirm) {
        this.awaitingResponse = undefined;
        this.snackBar.open('Je e-mails komen niet overeen.', '', {
          duration: 5000,
        });
        return;
      }
      const postal = this.formStep2.value.postal
        .replace(/\s/g, '')
        .toUpperCase();
      const name = this.formStep2.value.name;
      const phone = this.formStep2.value.phone;
      const city = this.formStep2.getRawValue().city;
      const street = this.formStep2.getRawValue().street;
      const houseNumber = this.formStep2.value.houseNumber;
      const houseNumberAddition = this.formStep2.value.houseNumberAddition;

      const address = `${street} ${houseNumber} ${houseNumberAddition}`;

      const companyName = this.formStep2.value.companyName;
      // getRawValue gets value even if input is disabled
      const deliveryStreet = this.formStep2.getRawValue().deliveryStreet;
      const deliveryHouseNumber =
        this.formStep2.getRawValue().deliveryHouseNumber;
      const deliveryHouseNumberAddition =
        this.formStep2.getRawValue().deliveryHouseNumberAddition;
      const deliveryPostal = this.formStep2
        .getRawValue()
        .deliveryPostal.replace(/\s+/g, '')
        .toUpperCase();
      const deliveryCity = this.formStep2.getRawValue().deliveryCity;
      const deliveryAddress = `${deliveryStreet} ${deliveryHouseNumber} ${deliveryHouseNumberAddition}`;

      const personalMessage = this.formStep3.value.personalMessage;

      let res: Observable<any>;
      console.log('email', email);
      console.log('postal', postal);

      const requestUrl = `${this.env.functionsUrl}/httpBuyVouchers`;

      const postData = {
        townshipId: this.townshipId,
        voucherGroupId: this.voucherGroupId,
        email,
        amount,
        name,
        postal,
        city,
        address,
        personalMessage,
        deliveryAddress,
        deliveryPostal,
        deliveryCity,
        voucherPrice: this.formStep1.value.value ?? null,
      } as any;

      if (this.paymentSystem === 'sisow') {
        postData.issuerId = issuerId;
      }
      if (this.paymentSystem === 'ideal') {
        postData.merchantId = this.township.idealMerchantId;
        postData.issuerId = this.formStep4.value.bank.id;
      }
      if (companyName) {
        postData['companyName'] = companyName;
      }
      if (deliveryStreet) {
        postData['deliveryStreet'] = deliveryStreet;
      }
      if (deliveryHouseNumber) {
        postData['deliveryHouseNumber'] = deliveryHouseNumber;
      }
      if (deliveryHouseNumberAddition) {
        postData['deliveryHouseNumberAddition'] = deliveryHouseNumberAddition;
      }
      if (deliveryPostal) {
        postData['deliveryPostal'] = deliveryPostal;
      }
      if (deliveryCity) {
        postData['deliveryCity'] = deliveryCity;
      }
      if (phone) {
        postData['phone'] = phone;
      }
      console.log('postData', postData);
      res = this.http.post(requestUrl, postData);
      res.subscribe((result) => {
        console.log('result', result);
        if (result.status === 'success') {
          console.log('result', result);
          // redirect to pay screen
          const windowRef = window.open(
            result.redirectUrl,
            '_top',
            'width=400,height=800'
          );
          windowRef.focus();
          this.router.navigate(['/complete-order']);
        } else {
          this.getTranslation(result.status);
        }
      });
    }
  }

  getTranslation(objName): void {
    this.translate
      .get(`snackbar-messages.${objName}`)
      .subscribe((res: string) => {
        this.snackBar.open(res, '', {
          duration: 12000,
        });
        return res;
      });
  }

  getBankImg(bankName: string) {
    let baseUrl = './assets/banks/';
    const newBankName = bankName.toLowerCase();
    if (newBankName.includes('rabo')) {
      baseUrl += 'Rabobank.png';
    } else if (newBankName.includes('ing')) {
      baseUrl += 'Ing.png';
    } else if (newBankName.includes('abn')) {
      baseUrl += 'ABNAmro.png';
    } else if (newBankName.includes('asn')) {
      baseUrl += 'ASNBank.png';
    } else if (newBankName.includes('knab')) {
      baseUrl += 'Knab.png';
    } else if (newBankName.includes('regiobank')) {
      baseUrl += 'RegioBank.png';
    } else if (newBankName.includes('sns')) {
      baseUrl += 'SNS.png';
    } else if (newBankName.includes('triodos')) {
      baseUrl += 'Triodos.png';
    } else if (newBankName.includes('lanschot')) {
      baseUrl += 'VanLanschot.png';
    } else {
      baseUrl += 'iDEAL.png';
    }
    return baseUrl;
  }
}
