import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TownshipPublicSettings, VoucherGroup } from '../interfaces';
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from 'firebase/firestore';
import { getHeaderImageUrl } from '../globals';

@Component({
  selector: 'app-group-link',
  templateUrl: './group-link.component.html',
  styleUrls: ['./group-link.component.scss'],
})
export class GroupLinkComponent implements OnInit {
  groupLink: string;
  townshipId: string;

  township: TownshipPublicSettings;
  townshipRef: any;
  headerImg: string;

  groupLinkForm: UntypedFormGroup;
  voucherGroups: VoucherGroup[] = [];

  waiting = false;
  groupLinkTxt: string;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.groupLink = params.groupLink;
      this.townshipId = params.townshipId;
      console.log('groupLink', this.groupLink);

      this.township = (
        await getDoc(
          doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
        )
      ).data() as TownshipPublicSettings;

      if (this.township.logoImageUrl) {
        this.headerImg = this.township.logoImageUrl;
      } else {
        this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
      }
      const voucherGroups = await this.db
        .collection<VoucherGroup>(
          `township/${this.townshipId}/voucherGroups`,
          (ref) => ref.where('groupLink', '==', this.groupLink)
        )
        .valueChanges({ idField: 'id' });
      voucherGroups.subscribe((newVoucherGroups) => {
        console.log('newVoucherGroups', newVoucherGroups);
        const updatedVoucherGroups = [];
        newVoucherGroups.forEach((voucherGroup: VoucherGroup) => {
          const updatedVoucherGroup = voucherGroup as any;
          console.log('location', location);
          let url = environment.iframeUrl;
          if (voucherGroup.type === 'sell') {
            url += 'buy/';
          } else {
            url += 'activate/';
          }
          url += `${this.townshipId}/${voucherGroup.id}`;
          updatedVoucherGroup.url = url;
          updatedVoucherGroups.push(updatedVoucherGroup);
        });
        this.voucherGroups = updatedVoucherGroups;
        newVoucherGroups.forEach(async (voucherGroup) => {
          if (voucherGroup.groupLinkTxt && !this.groupLinkTxt) {
            this.groupLinkTxt = voucherGroup.groupLinkTxt;
          }
          if (!voucherGroup.groupLinkTxt && !this.groupLinkTxt) {
            const q = query(
              collection(
                this.db.firestore,
                `township/${this.townshipId}/groupLinks`
              ),
              where('name', '==', this.groupLink)
            );
            const allGroupLinks = await getDocs(q);
            allGroupLinks.forEach((groupLink) => {
              if (groupLink.data().text) {
                this.groupLinkTxt = groupLink.data().text;
              }
            });
          }
        });
      });
    });
  }

  sortBy(name: string) {
    return this.voucherGroups.sort((voucherGroupA, voucherGroupB) =>
      voucherGroupA[name] > voucherGroupB[name]
        ? 1
        : voucherGroupA[name] === voucherGroupB[name]
        ? 0
        : -1
    );
  }

  async navigate(voucherGroupId) {
    this.waiting = true;
    console.log('voucherGroupId', voucherGroupId);

    const voucherGroupRef = doc(
      this.db.firestore,
      `township/${this.townshipId}/voucherGroups/`,
      `${voucherGroupId}`
    );
    const voucherGroup = (await getDoc(voucherGroupRef)).data() as VoucherGroup;
    console.log('voucherGroup', voucherGroup);

    let url = environment.iframeUrl;
    if (voucherGroup.type === 'sell') {
      url += 'buy/';
    } else {
      url += 'activate/';
    }
    url += `${this.townshipId}/${voucherGroupId}`;
    window.location.href = url;
    // }
    this.waiting = false;
  }
}
