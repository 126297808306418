// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar {
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}

.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  margin-right: 16px;
  align-items: center;
  height: 40px;
  src: "../../../assets/images/logos/groene_app_logo.png";
}

.title {
  margin: 0 16px;
  font: normal normal normal 18px Viga;
}`, "",{"version":3,"sources":["webpack://./src/app/passport/passport-navigation/passport-navigation.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEA;;EAEE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,uDAAA;AACF;;AAEA;EACE,cAAA;EACA,oCAAA;AACF","sourcesContent":[".nav-bar {\n  height: 56px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0 16px;\n}\n\n.left,\n.right {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.logo {\n  margin-right: 16px;\n  align-items: center;\n  height: 40px;\n  src: '../../../assets/images/logos/groene_app_logo.png';\n}\n\n.title {\n  margin: 0 16px;\n  font: normal normal normal 18px Viga;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
