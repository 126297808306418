// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-wrapper {
  height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  margin: 10px 0;
}

h4 {
  font-size: 14px;
}

p {
  font-size: 12px;
  margin: 6px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/find-voucher-groups/general-terms/general-terms.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;;;;;EAKE,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;AACF","sourcesContent":[".terms-wrapper {\n  height: 160px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n  font-weight: 500;\n  margin: 10px 0;\n}\n\nh4 {\n  font-size: 14px;\n}\n\np {\n  font-size: 12px;\n  margin: 6px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
