import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
export interface DialogData {
  pin: string;
  townshipId: string;
}

@Component({
  selector: 'app-verify-pin',
  templateUrl: './verify-pin.component.html',
  styleUrls: ['./verify-pin.component.scss'],
})
export class VerifyPinComponent implements OnInit {
  verifyPinForm: UntypedFormGroup;
  highContrast: boolean = false;

  constructor(
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VerifyPinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.verifyPinForm = this.fb.group({
      code: ['', [Validators.required]],
    });

    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    if (this.data.pin) {
      this.getSnackbar('wrong_pin');
    }
  }

  getSnackbar(message: string) {
    let snackbarMessage = this.translate.instant(
      `snackbar-messages.${message}`
    ) as string;
    console.log('snackbarMessage', snackbarMessage);
    if (snackbarMessage.includes('snackbar-messages.')) {
      // translation not found, get oops error instead
      snackbarMessage = this.translate.instant(
        `snackbar-messages.something-went-wrong`
      ) as string;
    }
    this.snackbar.open(snackbarMessage, 'X', {
      duration: 12000,
    });
    return message;
  }

  submit() {
    if (this.verifyPinForm.valid) {
      this.dialogRef.close(this.verifyPinForm.value.code);
    }
  }
}
