// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  text-transform: uppercase;
}

.cancel-button {
  color: #006239;
}

.submit-button {
  background-color: #006239;
  color: white;
}

.verify-form {
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/find-voucher-groups/dialogs/verify-sms/verify-sms.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AAEF;;AACA,YAAA;AACA;EACE,0BAAA;AAEF;;AAAA;EACE,yBAAA;AAGF;;AAAA;EACE,cAAA;AAGF;;AAAA;EACE,yBAAA;EACA,YAAA;AAGF;;AAAA;EACE,aAAA;EACA,eAAA;AAGF","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\nbutton {\n  text-transform: uppercase;\n}\n\n.cancel-button {\n  color: #006239;\n}\n\n.submit-button {\n  background-color: #006239;\n  color: white;\n}\n\n.verify-form {\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
