export const environment = {
  production: false,
  language: 'nl',
  firebase: {
    apiKey: 'AIzaSyCBi6Dvcx73fZwU5HnMn2jUarL4viX05L8',
    authDomain: 'wemaron-vouchers-dev.firebaseapp.com',
    databaseURL: 'https://wemaron-vouchers-dev.firebaseio.com',
    projectId: 'wemaron-vouchers-dev',
    storageBucket: 'wemaron-vouchers-dev.appspot.com',
    messagingSenderId: '804844036454',
    appId: '1:804844036454:web:b365c1a59fba197dca28a7',
    measurementId: 'G-JQ5FFXX8MT',
  },
  functionsUrl: 'https://europe-west1-wemaron-vouchers-dev.cloudfunctions.net',
  iframeUrl: 'https://dev.frame.lokalebon.nl/',
  loginMethod: {
    phone: false,
    email: true,
  },
  name: {
    name: 'Hallo Bon',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  recaptchaPublicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  themeColors: {
    primary: '#006239',
    secondary: '#913a00',
  },
};
