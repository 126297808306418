// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  margin: auto;
  margin-top: 12px;
  text-align: center;
  overflow-y: scroll;
  height: 80vh;
}
mat-card .status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 6px;
}
mat-card img {
  width: 200px;
}
mat-card .barcode {
  width: 250px;
}

.coupon-status {
  display: flex;
  place-content: flex-start;
  align-items: center;
}

.spinner-wrapper {
  height: 100vh;
  display: flex;
  place-content: center;
  align-items: center;
}

.no-bold {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/landing-email/landing-email.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AACE;EACE,YAAA;AACJ;AACE;EACE,YAAA;AACJ;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AACA;EACE,mBAAA;AAEF","sourcesContent":["mat-card {\n  // width: 575px;\n  margin: auto;\n  margin-top: 12px;\n  text-align: center;\n  overflow-y: scroll;\n  height: 80vh;\n  .status {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    margin-right: 6px;\n  }\n  img {\n    width: 200px;\n  }\n  .barcode {\n    width: 250px;\n  }\n}\n.coupon-status {\n  display: flex;\n  place-content: flex-start;\n  align-items: center;\n}\n\n.spinner-wrapper {\n  height: 100vh;\n  display: flex;\n  place-content: center;\n  align-items: center;\n}\n.no-bold {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
