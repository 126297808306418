import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TownshipPublicSettings } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { getDoc, doc } from 'firebase/firestore';
import { getHeaderImageUrl, getEnvName } from '../globals';

@Component({
  selector: 'app-request-vouchers',
  templateUrl: './request-vouchers.component.html',
  styleUrls: ['./request-vouchers.component.scss'],
})
export class RequestVouchersComponent implements OnInit {
  env = environment;
  awaitingResponse: boolean;
  townshipId: string;
  type: string;
  township: TownshipPublicSettings;
  requestForm: UntypedFormGroup;

  reqDigital: boolean;
  environment: string;
  error: string;
  recaptcha: boolean;

  recaptchaKey: string;
  headerImg: string;
  envName: any;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.requestForm = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      emailConfirm: [, [Validators.required, Validators.email]],
      voucher: [, Validators.required],
    });
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.recaptcha = false;
    this.recaptchaKey = this.env.recaptchaPublicKey;
    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      this.type = params.type;
      if (this.type === 'digital') {
        this.township = (
          await getDoc(
            doc(
              this.db.firestore,
              `township/${this.townshipId}/settings/public`
            )
          )
        ).data() as TownshipPublicSettings;

        if (this.township.logoImageUrl) {
          this.headerImg = this.township.logoImageUrl;
        } else {
          this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
        }
        this.envName = getEnvName(this.township.voucherPrefix);
      }
    });

    this.requestForm.valueChanges.subscribe(() => {
      if (this.error) {
        this.error = '';
      }
    });
  }
  getError(name) {
    const field = this.requestForm.get(name);
    if (field.touched || !field.pristine) {
      let error;

      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.instant(`errors.${error}`) as string;
      return res;
    }
  }

  requestVoucher(): void {
    this.awaitingResponse = true;
    if (this.requestForm.valid) {
      const data = this.requestForm.value;

      if (data.email !== data.emailConfirm) {
        this.requestForm.controls.emailConfirm.setErrors({
          notMatched: true,
        });
        this.awaitingResponse = false;
        this.error = this.getTranslation('email-dont-match');
        return;
      }
      const requestUrl = `${this.env.functionsUrl}/httpReqDigitalVoucher?townshipId=${this.townshipId}&voucher=${data.voucher}&email=${data.email}`;
      let res: Observable<any>;
      res = this.http.get(requestUrl, { responseType: 'text' });
      res.subscribe((result) => {
        console.log('result', result);
        switch (result) {
          case 'succeed':
            this.requestForm.reset();
            this.snackBar.open(this.getTranslation('bon-sent'), '', {
              duration: 12000,
            });
            break;
          case 'not_purchased':
            this.error = this.getTranslation('not_purchased');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
          case 'unknown_voucher':
            this.error = this.getTranslation('doesnt_exists');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
          case 'email_error':
            this.error = this.getTranslation('email_already_used');
            this.requestForm.controls.voucher.setErrors({
              notMatched: true,
            });
            break;
        }
        this.awaitingResponse = false;
      });
    }
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.recaptcha = true;
    } else {
      this.recaptcha = false;
    }
  }

  getTranslation(objName): string {
    const message = this.translate.instant(
      `snackbar-messages.${objName}`
    ) as string;
    return message;
  }
}
