import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivateVoucherComponent } from 'src/app/activate-voucher/activate-voucher.component';
import { VoucherGroup } from 'src/app/interfaces';

export interface DialogData {
  townshipId: string;
  voucherGroupId: string;
  postal: string;
  houseNumber: string;
  houseNumberAddition?: string;
  voucherGroup: VoucherGroup;
  headerImage: string;
}

@Component({
  selector: 'app-voucher-info',
  templateUrl: './voucher-info.component.html',
  styleUrls: ['./voucher-info.component.scss'],
})
export class VoucherInfoComponent implements OnInit {
  routerData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) {
    this.routerData = data;
    console.log('this.routerData', this.routerData);
  }

  ngOnInit(): void {}

  openRequestDialog() {
    const dialogRef = this.dialog.open(ActivateVoucherComponent, {
      width: '360px',
      data: {
        townshipId: this.routerData.townshipId,
        voucherGroupId: this.routerData.voucherGroupId,
        userData: {
          postal: this.routerData.postal,
          houseNumber: this.routerData.houseNumber,
          houseNumberAddition: this.routerData.houseNumberAddition,
        },
      },
      panelClass: ['activate-dialog'],
      disableClose: true,
    });
  }
}
