// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Viga;
  src: url('Viga-Regular.ttf');
}
::ng-deep .mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 26px;
}

::ng-deep .mat-mdc-unelevated-button:not(:disabled),
::ng-deep .mat-mdc-raised-button:not(:disabled) {
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important;
}

::ng-deep .mat-mdc-unelevated-button:not(:disabled).header-button {
  background-color: white !important;
  color: var(--primary) !important;
}

::ng-deep .mdc-button {
  font-size: 18px;
}

::ng-deep .mdc-button:focus {
  border: 2px solid rgba(0, 0, 0, 0.5);
  opacity: 0.9;
}

::ng-deep .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint),
::ng-deep .half-fields .mat-mdc-form-field-subscript-wrapper {
  margin: 0 0 14px 0;
}
@media screen and (max-width: 625px) {
  ::ng-deep .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint),
  ::ng-deep .half-fields .mat-mdc-form-field-subscript-wrapper {
    margin: 0 0 28px 0;
  }
}

::ng-deep .mat-mdc-form-field-hint-wrapper {
  padding: 0 0 0 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,4BAAA;AACF;AAEA;EACE,iDAAA;AAAF;;AAGA;;EAEE,2CAAA;EACA,yCAAA;AAAF;;AAGA;EACE,kCAAA;EACA,gCAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,oCAAA;EACA,YAAA;AAAF;;AAGA;;EAEE,kBAAA;AAAF;AACE;EAHF;;IAII,kBAAA;EAGF;AACF;;AAAA;EACE,8BAAA;AAGF","sourcesContent":["@font-face {\n  font-family: Viga;\n  src: url('../assets/fonts/Viga/Viga-Regular.ttf');\n}\n\n::ng-deep .mat-mdc-form-field {\n  --mat-form-field-container-text-line-height: 26px;\n}\n\n::ng-deep .mat-mdc-unelevated-button:not(:disabled),\n::ng-deep .mat-mdc-raised-button:not(:disabled) {\n  background-color: var(--primary) !important;\n  color: var(--primary-contrast) !important;\n}\n\n::ng-deep .mat-mdc-unelevated-button:not(:disabled).header-button {\n  background-color: white !important;\n  color: var(--primary) !important;\n}\n\n::ng-deep .mdc-button {\n  font-size: 18px;\n}\n\n::ng-deep .mdc-button:focus {\n  border: 2px solid rgba(0, 0, 0, 0.5);\n  opacity: 0.9;\n}\n\n::ng-deep .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint),\n::ng-deep .half-fields .mat-mdc-form-field-subscript-wrapper {\n  margin: 0 0 14px 0;\n  @media screen and (max-width: 625px) {\n    margin: 0 0 28px 0;\n  }\n}\n\n::ng-deep .mat-mdc-form-field-hint-wrapper {\n  padding: 0 0 0 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
