import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import {
  Theme,
  TownshipPublicSettings,
  VoucherGroup,
} from 'src/app/interfaces';
import { map, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DialogData,
  VerifyPhoneComponent,
} from '../activate-voucher/dialogs/verify-phone/verify-phone.component';
import * as moment from 'moment';
import { SucceedActivationComponent } from '../activate-voucher/dialogs/succeed-activation/succeed-activation.component';

import { startsWith } from '../activate-voucher/activate-voucher.component';
import { decodeRouteParam, fixTermsUrl, getEnvName } from '../globals';

@Component({
  selector: 'app-tb-activate-voucher',
  templateUrl: './tb-activate-voucher.component.html',
  styleUrls: ['./tb-activate-voucher.component.scss'],
})
export class TbActivateVoucherComponent implements OnInit {
  env = environment;
  envName: any = {};
  townshipId: string;
  township: TownshipPublicSettings;
  voucherGroupId: string;
  voucherGroupDoc: AngularFirestoreDocument;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupData: VoucherGroup;
  townshipUrlFixed: string;
  availableVouchers: number;
  validDateToRequest: boolean = true;
  themeId: string;
  theme: Theme;
  awaitingResponse: boolean;
  latestFormValue: any;
  startDate: Date;

  headerImg: string;
  logoImg: string =
    'https://firebasestorage.googleapis.com/v0/b/wemaron-vouchers.appspot.com/o/img%2Flogos%2FToegangsBonLogo.png?alt=media&token=4ba400bd-4fe4-49fe-9ce3-c8eade4dcfda';

  activateVoucherForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    emailConfirm: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    available: new FormControl(false, [Validators.requiredTrue]),
    // unavailable: new FormControl(false, [Validators.requiredTrue]),
    remarks: new FormControl(),
    terms: new FormControl(false, [Validators.requiredTrue]),
  });
  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private translate: TranslateService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      if (params.townshipId && params.voucherGroupId) {
        this.townshipId = decodeRouteParam(params.townshipId);
        this.voucherGroupId = decodeRouteParam(params.voucherGroupId);
        this.getInfo();
      }
    });

    this.activateVoucherForm.valueChanges.subscribe((form) => {
      const prevForm = { ...this.latestFormValue };
      this.latestFormValue = form;
      // console.log('prevForm', prevForm);
      // console.log('form', form);

      // if (!prevForm?.available && form.available) {
      //   this.activateVoucherForm.controls.unavailable.disable();
      // }
      // if (prevForm?.available && !form.available) {
      //   this.activateVoucherForm.controls.unavailable.enable();
      // }

      if (!prevForm?.unavailable && form.unavailable) {
        this.activateVoucherForm.controls.available.disable();
      }
      if (prevForm?.unavailable && !form.unavailable) {
        this.activateVoucherForm.controls.available.enable();
      }
    });
  }

  async getInfo() {
    this.voucherGroupDoc = this.db.doc(
      `township/${this.townshipId}/voucherGroups/${this.voucherGroupId}`
    );
    this.township = (
      await getDoc(
        doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
      )
    ).data() as TownshipPublicSettings;
    this.townshipUrlFixed = fixTermsUrl(this.township.termsUrl);
    this.envName = getEnvName(this.township.voucherPrefix);

    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as VoucherGroup;
        data.id = actions.payload['id'];

        data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
        data.activatedVouchers = data.activatedVouchers
          ? data.activatedVouchers
          : 0;

        if (data.maxVouchers && data.totalVouchers > data.maxVouchers) {
          data.totalVouchers = data.maxVouchers;
        }
        if (!data.claimedVouchers) {
          data.claimedVouchers = 0;
        }
        if (!data.activatedVouchers) {
          data.activatedVouchers = 0;
        }
        if (!data.reservedVouchers) {
          data.reservedVouchers = 0;
        }
        if (!data.toBeActivated) {
          data.toBeActivated = 0;
        }
        const reservedVouchers = data.reservedVouchers + data.toBeActivated;
        this.availableVouchers =
          data.totalVouchers - data.activatedVouchers - reservedVouchers;
        if (data.maxVouchers && data.maxVouchers < data.totalVouchers) {
          this.availableVouchers = data.maxVouchers - data.activatedVouchers;
        }

        if (data.selectedVoucherImageUrl) {
          this.headerImg = data.selectedVoucherImageUrl;
        }

        if (data.validUntilDate) {
          // Check if you are requesting a voucher within the allowed timeframe
          const dateThatIsValid = new Date(
            data.validUntilDate.toDate()
          ).setDate(
            data.validUntilDate.toDate().getDate() -
              (data.requestCouponDays ? data.requestCouponDays : 0)
          );
          console.log('dateThatIsValid', new Date(dateThatIsValid));
          if (moment(new Date()).isAfter(dateThatIsValid)) {
            this.validDateToRequest = false;
          }
        }

        console.log('availableVouchers', this.availableVouchers);
        if (this.availableVouchers <= 0 && data.type !== 'requestActivated') {
          this.activateVoucherForm.disable();
        } else if (!this.awaitingResponse) {
          this.activateVoucherForm.enable();
        }

        if (!this.validDateToRequest) {
          this.activateVoucherForm.disable();
        }
        return { ...data };
      })
    );
    this.voucherGroup.subscribe((group) => {
      console.log('group', group);
      this.voucherGroupData = group;
      if (group.verifyPhone) {
        this.activateVoucherForm.addControl(
          'phone',
          new FormControl('06', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            startsWith('06'),
          ])
        );
      }

      if (group.themeId && group.themeRef && !this.themeId) {
        this.getTheme(group.themeId, group.themeRef);
      }
      if (group.startDate) {
        this.startDate = group.startDate.toDate();
      }
      var r = document.querySelector(':root') as any;
      if (group.titleColor) {
        r.style.setProperty('--title-color', group.titleColor);
      }
      if (group.defaultTextColor) {
        r.style.setProperty('--text-color', group.defaultTextColor);
      }
      if (group.backgroundColor) {
        r.style.setProperty('--background-color', group.backgroundColor);
      }
    });
  }
  async getTheme(themeId: string, themeRef: DocumentReference) {
    this.themeId = themeId;
    this.theme = (await getDoc(themeRef)).data() as Theme;
  }

  getTranslation(objName: string) {
    let message = this.translate.instant(
      `snackbar-messages.${objName}`
    ) as string;
    console.log('message', message);
    if (message.includes('snackbar-messages.')) {
      // translation not found, get oops error instead
      message = this.translate.instant(
        `snackbar-messages.something-went-wrong`
      ) as string;
    }
    this.snackBar.open(message, 'X', {
      duration: 12000,
    });
    return message;
  }

  verifyPhone(phone: string) {
    const dialogRef = this.dialog.open(VerifyPhoneComponent, {
      width: '350px',
      data: { townshipId: this.townshipId, phone },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (code) => {
      if (code) {
        this.submit(code !== 'skip' ? code : null);
      }
    });
  }

  getError(name: string) {
    const field = this.activateVoucherForm.get(name);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'must_start_with_06';
      }
      if (field.hasError('number')) {
        error = 'number';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.instant(`errors.${error}`);
      return res as string;
    }
    return '';
  }

  async submit(verificationCode?: string) {
    if (this.awaitingResponse) {
      return;
    }
    if (!this.activateVoucherForm.valid) {
      this.activateVoucherForm.markAllAsTouched();
      return;
    }
    this.awaitingResponse = true;
    const form = this.activateVoucherForm.value;
    this.activateVoucherForm.disable();
    const email = form.email as string;
    const emailConfirm = form.emailConfirm as string;
    if (email !== emailConfirm) {
      this.awaitingResponse = undefined;
      this.activateVoucherForm.enable();
      this.snackBar.open(this.getTranslation('email-dont-match'), '', {
        duration: 5000,
      });
      return;
    }
    let postal: string;
    let houseNumber: string;
    let houseNumberAddition: string;
    const code = form.number ? form.number : null;
    const name = form.name ? form.name : null;
    const phone = form.phone ? form.phone : null;
    const identification = form.identification ? form.identification : null;
    let res: Observable<any>;

    const requestUrl = `${this.env.functionsUrl}/httpVoucher`;
    const body = {
      townshipId: this.townshipId,
      voucherGroupId: this.voucherGroupId,
      email,
      postal: postal ? postal : null,
      houseNumber: houseNumber ? houseNumber : null,
      houseNumberAddition:
        houseNumberAddition && houseNumberAddition.length
          ? houseNumberAddition.toLowerCase()
          : null,
      code: code ? code : null,
      name: name ? name : null,
      phone: phone ? phone : null,
      verificationCode: verificationCode ? verificationCode : null,
      identification: identification ? identification : null,
    };
    console.log('body', body);
    res = this.http.post(requestUrl, body);
    res.subscribe((result) => {
      this.awaitingResponse = undefined;
      console.log('result', result);
      if (
        result.status === 'verification_code_sent' ||
        result.status === 'verification_code_incorrect'
      ) {
        // Should show verification screen
        if (result === 'verification_code_incorrect') {
          this.getTranslation(result.status);
        }
        this.verifyPhone(phone);
      } else if (result.status === 'success') {
        this.activateVoucherForm.reset();
        this.dialog.open(SucceedActivationComponent, {
          width: '350px',
          data: { voucherGroup: result.voucherGroup },
          disableClose: true,
        });
        this.getTranslation('success_tb');
      } else {
        this.getTranslation(result.status);
      }
      this.activateVoucherForm.enable();
    });
  }
}
