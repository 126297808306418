import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Address,
  TownshipPublicSettings,
  Voucher,
  VoucherGroup,
} from '../interfaces';
import * as moment from 'moment-timezone';
import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { getHeaderImageUrl } from '../globals';
@Component({
  selector: 'app-resend-voucher',
  templateUrl: './resend-voucher.component.html',
  styleUrls: ['./resend-voucher.component.scss'],
})
export class ResendVoucherComponent implements OnInit {
  resendVoucherForm: UntypedFormGroup;

  loadingPage = true;

  townshipId: string;
  township: TownshipPublicSettings;
  headerImg: string;

  readyToSend = false;
  isSending = false;
  waitingResponse = false;

  vouchers: Voucher[] = [];
  expiredVouchers: Voucher[] = [];
  usedVouchers: Voucher[] = [];
  notification: string;
  fieldToCheck: any[] = [];

  lastFormValue: any;
  loadingAddressSuffixes: boolean;
  public addresses: ReplaySubject<Address[]> = new ReplaySubject<Address[]>(1);

  // recaptcha: boolean;
  // recaptchaKey: string = environment.recaptchaPublicKey;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    this.resendVoucherForm = this.fb.group({
      postal: [, Validators.required],
      houseNumber: [, [Validators.required, number({ min: 1 })]],
      houseNumberAddition: [],
    });

    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      console.log('townshipId', this.townshipId);

      this.township = (
        await getDoc(
          doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
        )
      ).data() as TownshipPublicSettings;

      console.log('township', this.township);
      if (this.township.logoImageUrl) {
        this.headerImg = this.township.logoImageUrl;
      } else {
        this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
      }
      this.loadingPage = false;
    });

    this.resendVoucherForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(async (form) => {
        console.log('controlName', form);
        this.fieldToCheck = [];
        this.readyToSend = false;
        if (form.postal && form.houseNumber) {
          if (form.houseNumberAddition) {
            this.fieldToCheck.push({
              houseNumber: form.houseNumber as string,
              postal: form.postal.toUpperCase().replaceAll(' ', ''),
              houseNumberAddition: form.houseNumberAddition,
            });
          } else {
            this.fieldToCheck.push({
              houseNumber: form.houseNumber as string,
              postal: form.postal.toUpperCase().replaceAll(' ', ''),
            });
          }
        }
        // If postal and housenumber are set get possible suffixes
        if (form.houseNumber) {
          form.houseNumber = form.houseNumber.toString();
        } else {
          form.houseNumber = '';
        }
        const lastFormValue = { ...this.lastFormValue };
        this.lastFormValue = form;
        if (
          form.postal &&
          form.houseNumber &&
          form.postal.length > 0 &&
          form.houseNumber.length > 0 &&
          (lastFormValue.postal !== form.postal ||
            lastFormValue.houseNumber !== form.houseNumber)
        ) {
          const postal = form.postal.toUpperCase().replace(/\s+/g, '');
          this.loadingAddressSuffixes = true;
          const q = query(
            collection(
              this.db.firestore,
              `/township/${this.townshipId}/addresses/`
            ),
            where('postal', '==', postal),
            where('houseNumber', '==', form.houseNumber)
          );
          const querySnapshot = (await getDocs(q)).docs;
          const addresses = [];
          querySnapshot.forEach((addressDoc) => {
            const address: any = addressDoc.data();
            if (address.houseNumberAddition) {
              addresses.push(address);
            } else if (querySnapshot.length > 1) {
              address.houseNumberAddition = '';
              addresses.unshift(address);
            }
          });
          if (addresses.length > 0) {
            console.log('addresses', addresses);
            this.addresses.next(addresses);
          } else {
            this.addresses.next(null);
          }
        }
        // If postal or housenumber we're set, delete suffixes to let people enter their own suffixes again
        if (
          (!form.postal || !form.houseNumber) &&
          (lastFormValue.postal || lastFormValue.houseNumber)
        ) {
          this.loadingAddressSuffixes = false;
          this.addresses.next(null);
        }
      });

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
  }

  getError(name) {
    const field = this.resendVoucherForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('number')) {
        error = 'number';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.instant(`errors.${error}`) as string;
      return res;
    }
    return '';
  }

  async calculateValidUntil(voucherGroup: VoucherGroup, voucher: Voucher) {
    console.log('calculateValidUntil voucherGroup', voucherGroup);
    console.log(
      'calculateValidUntil voucher.activateDate',
      voucher.activateDate
    );
    let validUntilDate;
    if (voucherGroup) {
      if (voucherGroup.validUntilDate) {
        validUntilDate = voucherGroup.validUntilDate.toDate();
      }
      if (voucherGroup.validUntilTime && voucher.activateDate) {
        let activateDate;
        // Since we don't know if the calculateValidUntil function is called with a toDate already called we just try both scenario's
        try {
          activateDate = new Date(voucher.activateDate);
        } catch (e) {
          console.error(e);
          console.log('voucher', voucher);
        }
        console.log('activateDate', activateDate);
        const activateDateWithoutHours = new Date(
          activateDate.getFullYear(),
          activateDate.getMonth(),
          activateDate.getDate() + 1
        );
        const validDate = new Date(activateDateWithoutHours);
        if (
          voucherGroup.validUntilTimeType === 'days' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setDate(
            validDate.getDate() + voucherGroup.validUntilTimeValue
          );
        } else if (
          voucherGroup.validUntilTimeType === 'weeks' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setDate(
            validDate.getDate() + voucherGroup.validUntilTimeValue * 7
          );
        } else if (
          voucherGroup.validUntilTimeType === 'months' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setMonth(
            validDate.getMonth() + voucherGroup.validUntilTimeValue
          );
        } else if (
          voucherGroup.validUntilTimeType === 'years' &&
          voucherGroup.validUntilTimeValue
        ) {
          validDate.setFullYear(
            validDate.getFullYear() + voucherGroup.validUntilTimeValue
          );
        }
        if (!validUntilDate || validDate < validUntilDate) {
          validUntilDate = validDate;
        }
      }
    }
    // Overwrite if specifically mentioned in voucher.
    console.log('voucher.validUntilDate typeof', typeof voucher.validUntilDate);
    if (voucher.validUntilDate && voucher.validUntilDate !== null) {
      if (typeof voucher.validUntilDate === 'string') {
        validUntilDate = new Date(voucher.validUntilDate);
      } else {
        validUntilDate = new Date(voucher.validUntilDate.toDate());
      }
    }
    let validUntilString;
    let reversedString;
    if (validUntilDate) {
      console.log('validUntilDate', validUntilDate, typeof validUntilDate);
      let day = validUntilDate.getDate().toString();
      if (day.length === 1) {
        day = `0${day}`;
      }
      let month = (validUntilDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      const year = validUntilDate.getFullYear().toString();
      validUntilString = `${day}-${month}-${year}`;
      reversedString = `${year}-${month}-${day}`;
      console.log('string', validUntilString);
      console.log('reversedString', reversedString);
    } else {
      validUntilString = 'onbekend';
      reversedString = 'onbekend';
    }
    const yearFromNow = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    return {
      date: validUntilDate as Date,
      isValid:
        (validUntilDate as Date) >= new Date()
          ? voucher.claimDate
            ? false
            : true
          : false,
      showInUi: validUntilDate
        ? moment(validUntilDate).tz('Europe/Amsterdam').diff(yearFromNow, 'y') <
          -1
          ? false
          : true
        : true,
      string: validUntilString,
      reversedString,
    };
  }

  // resolved(captchaResponse: string) {
  //   if (captchaResponse) {
  //     this.recaptcha = true;
  //   } else {
  //     this.recaptcha = false;
  //   }
  // }

  async getVouchers() {
    // if (!this.recaptcha) {
    //   return;
    // }
    if (
      this.waitingResponse ||
      this.fieldToCheck.length === 0 ||
      !this.resendVoucherForm.valid
    ) {
      return;
    }
    this.waitingResponse = true;
    this.vouchers = [];
    this.expiredVouchers = [];
    this.usedVouchers = [];
    const tempValidVouchers = [];
    const tempExpiredVouchers = [];
    const tempUsedVouchers = [];
    if (this.readyToSend) {
      const res = this.translate.instant(
        `snackbar-messages.already-retrieved-vouchers`
      ) as string;
      return this.snackBar.open(res, 'X', {
        duration: 5000,
      });
    }
    const postData = {
      fieldToCheck: this.fieldToCheck,
      townshipId: this.townshipId,
    };
    console.log('postData', postData);

    const callable = this.fns.httpsCallable('httpGetVouchers');
    const res = await lastValueFrom(callable(postData));

    console.log('res', res);
    if (res.message === 'succeed') {
      if ((res.vouchers && res.vouchers.length === 0) || !res.vouchers) {
        const res = this.translate.instant(
          `resend-vouchers.notification`
        ) as string;
        this.notification = res;
      } else {
        for (const voucherObj of res.vouchers) {
          const voucher = voucherObj as Voucher;
          // if (voucher.validUntilDate) {
          //   try {
          //     voucher.validUntilDate = voucher.validUntilDate.toDate();
          //   } catch {}
          // }
          if (voucher.voucherGroup) {
            if (voucher.voucherGroupId === 'expired') {
              tempExpiredVouchers.push(voucher);
            } else if (voucher.claimDate) {
              tempUsedVouchers.push(voucher);
            } else {
              tempValidVouchers.push(voucher);
            }
          }
        }

        this.vouchers = tempValidVouchers;
        console.log('this.vouchers', this.vouchers);
        this.expiredVouchers = tempExpiredVouchers;
        console.log('this.expiredVouchers', this.expiredVouchers);
        this.usedVouchers = tempUsedVouchers;
        console.log('this.usedVouchers', this.usedVouchers);
        this.waitingResponse = false;
        if (this.vouchers.length === 0) {
          const res = this.translate.instant(
            `resend-vouchers.notification`
          ) as string;
          this.readyToSend = false;
          this.waitingResponse = false;
          return (this.notification = res);
        } else {
          this.readyToSend = true;
        }
      }
    } else {
      const res = this.translate.instant(
        `resend-vouchers.notification`
      ) as string;
      this.readyToSend = false;
      this.waitingResponse = false;
      return (this.notification = res);
    }
  }

  async resendVouchers(voucher: Voucher) {
    if (this.isSending) {
      return;
    }
    const vouchers = [voucher];
    this.isSending = true;
    const postData = {
      vouchers,
      townshipId: this.townshipId,
    };
    const callable = this.fns.httpsCallable(`httpResendVouchers`);

    try {
      const res = await lastValueFrom(callable(postData));
      voucher.pageStatus = 'done';
      console.log('res', res);
      this.readyToSend = false;
      this.isSending = false;
      const translation = this.translate.instant(
        `resend-vouchers.notification-done`
      ) as string;
      // this.resendVoucherForm.reset();
      // this.vouchers = [];
      // this.expiredVouchers = [];
      // this.usedVouchers = [];
      this.snackBar.open(translation, 'X', {
        duration: 7500,
      });
    } catch (e) {
      this.readyToSend = false;
      this.isSending = false;
      console.error(e);
      const translation = this.translate.instant(
        `snackbar-messages.something-went-wrong`
      ) as string;
      // this.resendVoucherForm.reset();
      // this.vouchers = [];
      // this.expiredVouchers = [];
      // this.usedVouchers = [];
      this.snackBar.open(translation, 'X', {
        duration: 7500,
      });
    }
  }
}

export function number(prms: { min?: number; max?: number } = {}): ValidatorFn {
  return (control: UntypedFormControl): { [key: string]: any } => {
    let val: number = control.value;
    console.log;
    if (!val && val !== 0) {
      return null;
    }
    if (isNaN(val) || /\D/.test(val.toString())) {
      return { number: true };
    } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
      return val < prms.min || val > prms.max ? { number: true } : null;
    } else if (!isNaN(prms.min)) {
      return val < prms.min ? { number: true } : null;
    } else if (!isNaN(prms.max)) {
      return val > prms.max ? { number: true } : null;
    } else {
      return null;
    }
  };
}
