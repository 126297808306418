import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateVoucherComponent } from './activate-voucher/activate-voucher.component';
import { ExchangeVoucherComponent } from './exchange-voucher/exchange-voucher.component';
import { BuyVouchersComponent } from './buy-vouchers/buy-vouchers.component';
import { BuySuccessComponent } from './buy-vouchers/buy-success/buy-success.component';
import { RequestVouchersComponent } from './request-vouchers/request-vouchers.component';
import { CompleteOrderComponent } from './buy-vouchers/complete-order/complete-order.component';
import { SaldoCheckerComponent } from './saldo-checker/saldo-checker.component';
import { ResendVoucherComponent } from './resend-voucher/resend-voucher.component';
import { GroupLinkComponent } from './group-link/group-link.component';
import { FindVoucherGroupComponent } from './find-voucher-group/find-voucher-group.component';
import { VoucherInfoComponent } from './voucher-info/voucher-info/voucher-info.component';
import { PreFindVoucherGroupComponent } from './pre-find-voucher-group/pre-find-voucher-group.component';
import { TbActivateVoucherComponent } from './tb-activate-voucher/tb-activate-voucher.component';
import { FindVoucherGroupsComponent } from './find-voucher-groups/find-voucher-groups.component';
import { BurdenOfProofFormComponent } from './burden-of-proof-form/burden-of-proof-form.component';
import { CreatePassportComponent } from './passport/create-passport/create-passport.component';
import { environment } from 'src/environments/environment';

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
// const redirectLoggedInToDefault = () => redirectLoggedInTo(['dashboardtownship']);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
const routes: Routes = [
  { path: '', component: ActivateVoucherComponent },
  {
    path: 'activate/:townshipId',
    component: ActivateVoucherComponent,
    title: `${environment.name.name} - Aanvragen`,
  },
  {
    path: 'activate/:townshipId/:voucherGroupId',
    component: ActivateVoucherComponent,
    title: `${environment.name.name} - Aanvragen`,
  },
  {
    path: 'activate/:townshipName/:voucherGroupId/:address',
    component: ActivateVoucherComponent,
    title: `${environment.name.name} - Aanvragen`,
  },
  {
    path: 'activate/:townshipName/:voucherGroupId/:address/:customParams',
    component: ActivateVoucherComponent,
    title: `${environment.name.name} - Aanvragen`,
  },
  {
    path: 'activate-tb/:townshipId/:voucherGroupId',
    component: TbActivateVoucherComponent,
  },
  {
    path: 'pre-find-voucher-group',
    component: PreFindVoucherGroupComponent,
  },
  {
    path: 'pre-find-voucher-group/:townshipId',
    component: PreFindVoucherGroupComponent,
  },
  {
    path: 'find-voucher-group',
    component: FindVoucherGroupComponent,
  },
  {
    path: 'find-voucher-group/:address',
    component: FindVoucherGroupComponent,
  },
  {
    path: 'find-voucher-group/:address/:townshipId',
    component: FindVoucherGroupComponent,
  },
  {
    path: 'municipality/:townshipName',
    component: FindVoucherGroupsComponent,
    title: `${environment.name.name} - Aanvraagmodule`,
  },
  {
    path: 'municipality/:townshipName/:customParams',
    component: FindVoucherGroupsComponent,
    title: `${environment.name.name} - Aanvraag voorbereiden`,
  },
  {
    path: 'exchange/:townshipId/:organisationId',
    component: ExchangeVoucherComponent,
  },
  {
    path: 'buy/:townshipId/:voucherGroupId',
    component: BuyVouchersComponent,
    title: `${environment.name.name} - Koopmodule`,
  },
  {
    path: 'success',
    component: BuySuccessComponent,
  },
  {
    path: 'success/:townshipId/:voucherGroupdId/:orderId',
    component: BuySuccessComponent,
    title: `${environment.name.name} - Bevestiging bestelling`,
  },
  {
    path: 'request/:townshipId/:type',
    component: RequestVouchersComponent,
  },
  {
    path: 'complete-order',
    component: CompleteOrderComponent,
  },
  {
    path: 'complete-order/:orderId',
    component: CompleteOrderComponent,
  },
  {
    path: 'saldo-checker/:townshipId',
    component: SaldoCheckerComponent,
    title: `${environment.name.name} - Saldo checken`,
  },
  {
    path: 'resend-voucher/:townshipId',
    component: ResendVoucherComponent,
  },
  {
    path: 'group-link/:townshipId/:groupLink',
    component: GroupLinkComponent,
  },
  {
    path: 'voucher-info',
    component: VoucherInfoComponent,
  },
  {
    path: 'burden-of-proof/:townshipName/:encryptedId/:postal/:houseNumber',
    component: BurdenOfProofFormComponent,
    title: `${environment.name.name} - Bewijslastformulier`,
  },
  {
    path: 'passport/:townshipName',
    component: CreatePassportComponent,
    title: `${environment.name.name} - Woningpaspoort`,
  },
  // {
  //   path: 'email',
  //   component: LandingEmailComponent,
  // },
];

console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
