import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  query,
  collection,
  where,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivateVoucherComponent } from '../activate-voucher/activate-voucher.component';
import {
  fixTermsUrl,
  getEnvName,
  getHeaderImageUrl,
  getTownshipColors,
  hexToRgb,
} from '../globals';
import {
  Address,
  GroupLink,
  TownshipPublicSettings,
  VoucherGroup,
} from '../interfaces';
import { VoucherInfoComponent } from '../voucher-info/voucher-info/voucher-info.component';
import { VerifyPinComponent } from './../find-voucher-groups/dialogs/verify-pin/verify-pin.component';
import { VerifySmsComponent } from './../find-voucher-groups/dialogs/verify-sms/verify-sms.component';

@Component({
  selector: 'app-find-voucher-group',
  templateUrl: './find-voucher-group.component.html',
  styleUrls: ['./find-voucher-group.component.scss'],
})
export class FindVoucherGroupComponent implements OnInit {
  loaded: boolean = false;
  awaitingResponse: boolean;
  noActions: boolean = false;
  nonLinkedSearch: boolean;
  errorMessage: string;
  townshipId: string;
  voucherGroupId: string;
  township: TownshipPublicSettings;
  townshipName: string;
  voucherGroups: VoucherGroup[];
  groupLinkedVoucherGroups: VoucherGroup[];
  nonGroupLinkedVoucherGroups: VoucherGroup[];
  voucherGroupGroupLinks: any[];
  filteredVoucherGroups: VoucherGroup[] = [];
  groupLinksFullInfo: any[];
  groupLinks: GroupLink[];
  checked: boolean;
  searchVoucherGroupForm = new UntypedFormGroup({
    postal: new UntypedFormControl('', Validators.required),
    houseNumber: new UntypedFormControl('', Validators.required),
    houseNumberAddition: new UntypedFormControl(''),
  });
  loadingAddressSuffixes: boolean;
  lastFormValue: any;
  public addresses: ReplaySubject<Address[]> = new ReplaySubject<Address[]>(1);
  headerImg: string;
  envName: any = {};
  termsUrl: string;
  savedFormInfo: {
    postal: string;
    houseNumber: string;
    houseNumberAddition: string;
  };
  theme: any = {};

  postal: string;
  phonenumber: string;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private translate: TranslateService,
    private fns: AngularFireFunctions,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      this.townshipId = params.townshipId;
      if (params.address) {
        const addressSplit = params.address.split('*');
        if (addressSplit.length == 1 && !this.townshipId) {
          // Param doens't include * so is townshipId
          this.townshipId = params.address;
        }
        if (addressSplit.length > 1) {
          const patchObj = {
            postal: addressSplit[0],
            houseNumber: addressSplit[1],
            houseNumberAddition: addressSplit[2],
          };
          this.searchVoucherGroupForm.patchValue(patchObj);
        }
        this.submit();
      }
      console.log('townshipId', this.townshipId);
      await this.getInfo();
    });

    this.searchVoucherGroupForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (form) => {
        if (form.houseNumber) {
          form.houseNumber = form.houseNumber.toString();
        } else {
          form.houseNumber = '';
        }
        const lastFormValue = { ...this.lastFormValue };
        this.lastFormValue = form;
        // If postal and housenumber are set get possible suffixes
        if (
          form.postal &&
          form.houseNumber &&
          form.postal.length > 0 &&
          form.houseNumber.length > 0 &&
          (lastFormValue.postal !== form.postal ||
            lastFormValue.houseNumber !== form.houseNumber)
        ) {
          this.postal = form.postal.toUpperCase().replace(/\s+/g, '');
          this.loadingAddressSuffixes = true;
          const q = query(
            collection(
              this.db.firestore,
              `/township/${this.townshipId}/addresses/`
            ),
            where('postal', '==', this.postal),
            where('houseNumber', '==', form.houseNumber)
          );
          const querySnapshot = (await getDocs(q)).docs;
          const addresses = [];
          querySnapshot.forEach((addressDoc) => {
            const address: any = addressDoc.data();
            if (address.houseNumberAddition) {
              addresses.push(address);
            } else if (querySnapshot.length > 1) {
              address.houseNumberAddition = '';
              addresses.unshift(address);
            }
          });
          if (addresses.length > 0) {
            this.addresses.next(addresses);
          } else {
            this.addresses.next(null);
          }
        }
        // If postal or housenumber we're set, delete suffixes to let people enter their own suffixes again
        if (
          (!form.postal || !form.houseNumber) &&
          (lastFormValue.postal || lastFormValue.houseNumber)
        ) {
          this.loadingAddressSuffixes = false;
          this.addresses.next(null);
        }
      });
  }

  getError(name) {
    const field = this.searchVoucherGroupForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'must_start_with_06';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.instant(`errors.${error}`) as string;
      return res;
    }
    return '';
  }

  async getInfo() {
    if (this.townshipId) {
      this.township = (
        await getDoc(
          doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
        )
      ).data() as TownshipPublicSettings;
    } else {
      this.nonLinkedSearch = true;
    }
    this.theme = await getTownshipColors(this.township);
    this.loaded = true;
  }

  async submit(pin?: string, phonenumber?: string, verificationCode?: string) {
    if (this.searchVoucherGroupForm.valid && !this.awaitingResponse) {
      const form = this.searchVoucherGroupForm.value;
      console.log('form', form);
      this.awaitingResponse = true;
      const postal = (form.postal as string).toUpperCase().replace(/\s+/g, '');
      console.log('test', postal);
      const houseNumber = form.houseNumber.toString();
      const houseNumberAddition = form.houseNumberAddition
        ? (form.houseNumberAddition as string)
        : null;
      const callable = this.fns.httpsCallable('httpSearchVoucherGroups');
      console.log('calling now');
      if (this.nonLinkedSearch) {
        this.townshipId = undefined;
        this.voucherGroups = undefined;
      }

      const result = await lastValueFrom(
        callable({
          townshipId: this.townshipId,
          postal: postal,
          houseNumber,
          houseNumberAddition,
          pin,
          phonenumber: phonenumber,
          verificationCode: verificationCode,
        })
      );
      console.log('result', result);
      if (result.townshipId) {
        this.townshipId = result.townshipId;
        this.getInfo();
      }
      this.awaitingResponse = false;
      if (result.status === 'pin') {
        return this.verifyPin(pin);
      } else if (result.status === 'address_not_found_in_system') {
        return (this.errorMessage =
          'find-voucher-group.error_address_not_found');
      } else if (
        result.status === 'smsVerificationRequired' ||
        result.status === 'verification_code_sent'
      ) {
        return this.verifySms(result.status);
      } else if (result.status === 'phonenumber_does_not_match') {
        return (this.errorMessage =
          'find-voucher-group.error_matching_phonenumber');
      } else if (
        result.status === 'phonenumber_already_coupled_to_other_address'
      ) {
        return (this.errorMessage =
          'find-voucher-group.error_coupled_phonenumber');
      }
      this.groupLinksFullInfo = []; // all grouplinks in a township
      const allGroupLinks = await getDocs(
        collection(this.db.firestore, `township/${this.townshipId}/groupLinks`)
      );
      allGroupLinks.forEach(async (groupLinkInfo) => {
        this.groupLinksFullInfo.push(groupLinkInfo.data());
      });
      if (result.voucherGroups) {
        this.groupLinkedVoucherGroups = []; //vouchergroups with a grouplink
        this.groupLinks = []; // all grouplinks(the objects) in the result of vouchergroups
        this.voucherGroupGroupLinks = []; // all grouplinks(only names) in the result of vouchergroups
        this.nonGroupLinkedVoucherGroups = []; //vouchergroups without a grouplink
        result.voucherGroups.forEach((resultVoucherGroup) => {
          if (resultVoucherGroup.groupLink) {
            this.groupLinkedVoucherGroups.push(resultVoucherGroup);
            if (
              !this.voucherGroupGroupLinks.includes(
                resultVoucherGroup.groupLink
              )
            ) {
              this.groupLinksFullInfo.forEach((groupLinksSelectedInfo) => {
                if (
                  groupLinksSelectedInfo.name === resultVoucherGroup.groupLink
                ) {
                  this.groupLinks.push(groupLinksSelectedInfo);
                }
              });
              this.voucherGroupGroupLinks.push(resultVoucherGroup.groupLink);
            }
          } else {
            this.nonGroupLinkedVoucherGroups.push(resultVoucherGroup);
          }
        });

        this.checkSituation();
        this.sortingOrder();

        this.voucherGroups = result.voucherGroups;
        this.savedFormInfo = {
          postal: postal,
          houseNumber,
          houseNumberAddition,
        };
        if (this.voucherGroups.length < 1) {
          this.noActions = true;
        } else {
          this.noActions = false;
        }
        console.log('noActions', this.noActions);
        this.errorMessage = undefined;
      } else if (result.error === 'errorAddress') {
        console.log('result', result);
        this.errorMessage = 'find-voucher-group.error_searching_address';
      } else {
        this.errorMessage = 'find-voucher-group.generic_error';
      }
    }
  }

  verifyPin(pin?: string) {
    const dialogRef = this.dialog.open(VerifyPinComponent, {
      width: '350px',
      data: { townshipId: this.townshipId, pin },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (code) => {
      if (code) {
        this.submit(code !== 'skip' ? code : null);
      }
    });
  }

  verifySms(status?: string) {
    let phoneSubmitted = false;
    if (status === 'smsVerificationRequired') {
      phoneSubmitted = false;
    } else if (status === 'verification_code_sent') {
      phoneSubmitted = true;
    }
    const dialogRef = this.dialog.open(VerifySmsComponent, {
      width: '350px',
      data: { phonenumberSubmitted: phoneSubmitted },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const phonenumber =
          result.phonenumber !== '' ? result.phonenumber : this.phonenumber;
        this.phonenumber = phonenumber;
        this.submit(null, this.phonenumber, result.code);
      }
    });
  }

  openInfoDialog(voucherGroup: VoucherGroup) {
    const form = this.searchVoucherGroupForm.value;
    const dialogRef = this.dialog.open(VoucherInfoComponent, {
      width: '360px',
      data: {
        townshipId: this.townshipId,
        voucherGroupId: voucherGroup.id,
        postal: this.postal,
        houseNumber: form.houseNumber,
        voucherGroup,
        headerImage: this.headerImg,
      },
      panelClass: ['activate-dialog'],
    });
  }

  openRequestDialog(voucherGroup: VoucherGroup) {
    const dialogRef = this.dialog.open(ActivateVoucherComponent, {
      width: '360px',
      data: {
        townshipId: this.townshipId,
        voucherGroupId: voucherGroup.id,
        userData: {
          postal: this.savedFormInfo.postal,
          houseNumber: this.savedFormInfo.houseNumber,
          houseNumberAddition: this.savedFormInfo.houseNumberAddition,
        },
      },
      panelClass: ['activate-dialog'],
      disableClose: true,
    });
  }

  getCurrencyString(number) {
    let newNumber;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }

  checkSituation() {
    this.filteredVoucherGroups = [];
    this.groupLinks.forEach((groupLink) => {
      this.groupLinkedVoucherGroups.forEach((voucherGroup) => {
        if (groupLink.name === voucherGroup.groupLink) {
          this.filteredVoucherGroups.push(voucherGroup);
        }
      });
    });

    let voucherGroupListToFilter = [...this.filteredVoucherGroups];
    let voucherGroupsDeleted = 0;

    this.filteredVoucherGroups.forEach((voucherGroup, index) => {
      this.groupLinks.forEach((groupLink) => {
        if (voucherGroup.hideVoucherGroupLink === groupLink.name) {
          console.log('voucherGroup.name to delete', voucherGroup.name);
          console.log('index', index);
          // filter out voucherGroups that have hideVoucherGroupLink match to a groupLink being shown.
          voucherGroupListToFilter.splice(index - voucherGroupsDeleted, 1);
          voucherGroupsDeleted++;
        }
      });
    });

    this.filteredVoucherGroups = [...voucherGroupListToFilter];
    let groupLinksToFilter = [...this.groupLinks];
    let groupLinksDeleted = 0;
    // filter out grouplinks with no vouchergroups linked to them
    this.groupLinks.forEach((groupLink, index) => {
      if (
        this.filteredVoucherGroups.filter(
          (voucherGroup) => groupLink.name === voucherGroup.groupLink
        ).length < 1
      ) {
        console.log('groupLink.name to delete', groupLink.name);
        console.log('index', index);
        groupLinksToFilter.splice(index - groupLinksDeleted, 1);
        groupLinksDeleted++;
      }
    });

    this.groupLinks = [...groupLinksToFilter];

    let newGroupLinks = [];
    this.groupLinks.forEach((groupLink) => {
      let voucherGroupsInGroupLink = [];
      console.log('grouplink', groupLink.name);
      this.filteredVoucherGroups.forEach((voucherGroup) => {
        if (voucherGroup.groupLink === groupLink.name) {
          voucherGroupsInGroupLink.push(voucherGroup);
        }
      });

      let obj = {
        'name': `${groupLink.name}`,
        'text': groupLink.text,
        'order': groupLink.order,
        'voucherGroups': voucherGroupsInGroupLink,
      };
      newGroupLinks.push(obj);
    });
    console.log('newGroupLinks', newGroupLinks);

    this.groupLinks = newGroupLinks;
  }

  sortingOrder() {
    this.groupLinks.sort((groupLink1, groupLink2) => {
      const a = groupLink1.order;
      const b = groupLink2.order;

      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
