import { TownshipInfo, TownshipPublicSettings } from './interfaces';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export function hexToRgb(hex: string, addOpacityValue?: number) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
    // console.log('rgb', rgb);
    let rgbString = addOpacityValue ? 'rgba(' : 'rgb(';
    rgbString = rgbString + `${rgb.r},${rgb.g},${rgb.b}`;
    rgbString = rgbString + (addOpacityValue ? `,${addOpacityValue})` : ')');
    // console.log('rgbString', rgbString);
    return {
      rgb,
      rgbString,
    };
  } else {
    return null;
  }
}

export function calculateTextColor(color: string) {
  const aRGBHex = color.replace('#', '').match(/.{1,2}/g);
  const RGB =
    1 -
    (0.299 * parseInt(aRGBHex[0], 16) +
      0.733 * parseInt(aRGBHex[1], 16) +
      0.114 * parseInt(aRGBHex[2], 16)) /
      255;

  return RGB < 0.45 ? '#000000' : '#FFFFFF';
}

export function decodeRouteParam(param: string) {
  const temp = decodeURIComponent(param);
  return window.atob(temp);
}

export function getCurrencyString(number: number) {
  let newNumber;
  if (!number.toString().includes('.')) {
    // this isnt a decimal
    newNumber = `${number.toString()},-`;
  } else {
    newNumber = `${number.toFixed(2)}`;
    newNumber = newNumber.replace('.', ',');
  }
  return newNumber;
}

export function getHeaderImageUrl(voucherPrefix: string): string {
  let headerImageUrl: string;
  switch (voucherPrefix) {
    case 'groene':
      headerImageUrl = '../../assets/images/groenebon.png';
      break;
    case 'toegangs':
      headerImageUrl = '../../assets/images/toegangsbon.png';
      break;
    case 'duurzaamwonen':
      headerImageUrl = '../../assets/images/duurzaamwonen.png';
      break;
    default:
      headerImageUrl = '../../assets/images/lokalebon.png';
      break;
  }
  return headerImageUrl;
}

export function fixTermsUrl(termsUrl: string): string {
  let fixedUrl: string;
  if (termsUrl.includes('https://')) {
    fixedUrl = termsUrl;
  } else if (termsUrl.includes('http://')) {
    fixedUrl = termsUrl.replace('http://', 'https://');
  } else {
    fixedUrl = 'https://' + termsUrl;
  }
  return fixedUrl;
}

export function getEnvName(voucherPrefix: string) {
  let envName;
  switch (voucherPrefix) {
    case 'groene':
      envName = { value: 'Groene Bon' };
      break;
    case 'toegangs':
      envName = { value: 'Toegangsbon' };
      break;
    case 'duurzaamwonen':
      envName = { value: 'Duurzaam Wonen Bon' };
      break;
    default:
      envName = { value: 'Lokale Bon' };
      break;
  }
  return envName;
}

export async function getTownshipInfo(options: {
  townshipName?: string;
  townshipId?: string;
}) {
  let townshipName = options.townshipName;
  let townshipId = options.townshipId;
  if (townshipName && !townshipId) {
    const townshipNameDoc = (
      await getDoc(doc(getFirestore(), `townshipNames/${townshipName}`))
    ).data();
    console.log('townshipNameDoc', townshipNameDoc);
    if (!townshipNameDoc.townshipId) {
      return;
    }
    townshipId = townshipNameDoc.townshipId;
  }
  if (!townshipId) {
    return;
  }
  const townshipRef = doc(
    getFirestore(),
    `township/${townshipId}/settings/public`
  );
  const township = (await getDoc(townshipRef)).data() as TownshipPublicSettings;
  townshipName = township.name;
  let townshipLogo: string = getHeaderImageUrl(township.voucherPrefix);
  if (township.logoImageUrl) {
    townshipLogo = township.logoImageUrl;
  }

  return {
    townshipId,
    township,
    townshipName,
    townshipLogo,
  } as TownshipInfo;
}

export async function getTownshipColors(township) {
  let theme = {
    primary: { background: '#006239', text: '#FFFFFF' },
    accent: { background: '#913a00', text: '#FFFFFF' },
    image: '../../assets/images/groenebon.png',
  };

  if (township) {
    let image: any;
    if (township.logoImageUrl) {
      image = township.logoImageUrl;
    } else {
      switch (township.voucherPrefix) {
        case 'lokale':
          image = '../../assets/images/lokalebon.png';
          break;
        case 'toegangs':
          image = '../../assets/images/toegangsbon.png';
          break;
        case 'duurzaamwonen':
          image = '../../assets/images/duurzaamwonen.png';
          break;
        default:
          image = '../../assets/images/groenebon.png';
          break;
      }
    }
    theme = {
      primary: {
        background: township.primaryColor ?? '#006239',
        text: calculateTextColor(township.primaryColor ?? '#006239'),
      },
      accent: {
        background: township.accentColor ?? '#913a00',
        text: calculateTextColor(township.accentColor ?? '#913a00'),
      },
      image: image ?? '../../assets/images/groenebon.png',
    };
  }
  const rootVariables = [
    { name: '--primary', value: theme.primary.background },
    { name: '--primary-contrast', value: theme.primary.text },
    { name: '--accent', value: theme.accent.background },
    { name: '--accent-contrast', value: theme.accent.text },
  ];
  for (const data of rootVariables) {
    document.documentElement.style.setProperty(data.name, data.value);
  }
  return theme;
}
