import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';

// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RecaptchaModule } from 'ng-recaptcha';
// import { LoginComponent } from './auth/login/login.component';
// import { RegistrationComponent } from './auth/registration/registration.component';
// import { InlineSVGModule } from 'ng-inline-svg';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { CustomDateAdapter } from './custom-date-adapter';

import { ActivateVoucherComponent } from './activate-voucher/activate-voucher.component';
import { ExchangeVoucherComponent } from './exchange-voucher/exchange-voucher.component';
import { BuyVouchersComponent } from './buy-vouchers/buy-vouchers.component';
import { BuySuccessComponent } from './buy-vouchers/buy-success/buy-success.component';
import { RequestVouchersComponent } from './request-vouchers/request-vouchers.component';
import { CompleteOrderComponent } from './buy-vouchers/complete-order/complete-order.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SaldoCheckerComponent } from './saldo-checker/saldo-checker.component';
import { ResendVoucherComponent } from './resend-voucher/resend-voucher.component';
import { GroupLinkComponent } from './group-link/group-link.component';
import { VerifyPhoneComponent } from './activate-voucher/dialogs/verify-phone/verify-phone.component';
import { LandingEmailComponent } from './landing-email/landing-email.component';
import { FindVoucherGroupComponent } from './find-voucher-group/find-voucher-group.component';
import { VoucherInfoComponent } from './voucher-info/voucher-info/voucher-info.component';
import { PreFindVoucherGroupComponent } from './pre-find-voucher-group/pre-find-voucher-group.component';
import { VerifyPinComponent } from './find-voucher-groups/dialogs/verify-pin/verify-pin.component';
import { VerifySmsComponent } from './find-voucher-groups/dialogs/verify-sms/verify-sms.component';
import { SucceedActivationComponent } from './activate-voucher/dialogs/succeed-activation/succeed-activation.component';
import { TbActivateVoucherComponent } from './tb-activate-voucher/tb-activate-voucher.component';
import { FindVoucherGroupsComponent } from './find-voucher-groups/find-voucher-groups.component';
import { GeneralTermsComponent } from './find-voucher-groups/general-terms/general-terms.component';
import { BurdenOfProofFormComponent } from './burden-of-proof-form/burden-of-proof-form.component';
import { CreatePassportComponent } from './passport/create-passport/create-passport.component';
import { PassportNavigationComponent } from './passport/passport-navigation/passport-navigation.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  hideRequiredMarker: true,
};

@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    // RegistrationComponent,
    ActivateVoucherComponent,
    ExchangeVoucherComponent,
    BuyVouchersComponent,
    BuySuccessComponent,
    RequestVouchersComponent,
    CompleteOrderComponent,
    SaldoCheckerComponent,
    ResendVoucherComponent,
    GroupLinkComponent,
    VerifyPhoneComponent,
    LandingEmailComponent,
    FindVoucherGroupComponent,
    VoucherInfoComponent,
    PreFindVoucherGroupComponent,
    VerifyPinComponent,
    SucceedActivationComponent,
    TbActivateVoucherComponent,
    VerifySmsComponent,
    FindVoucherGroupsComponent,
    GeneralTermsComponent,
    BurdenOfProofFormComponent,
    CreatePassportComponent,
    PassportNavigationComponent,
  ],
  imports: [
    AppRoutingModule,
    // AvatarModule,
    // NgxCsvParserModule,
    // CalendarModule.forRoot({
    //   provide: AngularCalendarDateAdapter,
    //   useFactory: adapterFactory
    // }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBa8IaoNVTApq_VIRtKq45Kl5RMhf_fcIc',
    //   libraries: ['places']
    // }),
    // InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    AngularFireFunctionsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    // MatRippleModule,
    MatCardModule,
    MatCheckboxModule,
    // MatBadgeModule,
    MatFormFieldModule,
    MatCardModule,
    MatRadioModule,
    MatChipsModule,
    // MatGridListModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    // NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatNativeDateModule,
    // MatDatepickerModule,
    // MatSlideToggleModule,
    MatSelectModule,
    // MatAutocompleteModule,
    // NgxChartsModule,
    RecaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    { provide: REGION, useValue: 'europe-west1' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
